import React, { useState, useEffect } from 'react';
import { useGetUmbracoContentByTypeQuery } from '../../api/useGetUmbracoContentByTypeQuery';
import styles from './results.module.scss';
import Loader from '../loader/Loader';

interface Item {
    id: string;
    content: {
        properties: {
            productName: string;
            brand: string;
            minatureImage: Array<{
                url: string;
                name: string;
            }>;
        };
    };
}

interface UmbracoContent {
    contentType: string;
    name: string;
    createDate: string;
    updateDate: string;
    id: string;
    route: {
        path: string;
    };
    properties: {
        addItem: {
            items: Item[];
        } | null;
        shopPagePhoto: Array<{
            url: string;
            name: string;
        }> | null;
        location?: string | null;
        tags?: string[] | null;
    };
}

interface ResultsProps {
    searchTerm: string;
}

const Results: React.FC<ResultsProps> = ({ searchTerm }) => {
    const contentTypeAlias = 'addShopPage';
    const { data, error, isLoading } =
        useGetUmbracoContentByTypeQuery(contentTypeAlias);
    const decodedSearchTerm = searchTerm?.replace(/-/g, ' ') || '';
    const [searchTags, setSearchTags] = useState<string[]>([]);

    useEffect(() => {
        setSearchTags(
            decodedSearchTerm.split(' ').filter(tag => tag.trim() !== ''),
        );
    }, [decodedSearchTerm]);

    if (isLoading) return <Loader />;
    if (error) return <div>Error: {error.message}</div>;

    const items = data || [];

    const filteredShopPagePhotos = Array.from(
        items.reduce<Set<string>>((accum, shopPage: UmbracoContent) => {
            const location = shopPage.properties.location ?? '';
            const tags = shopPage.properties.tags ?? [];
            if (
                location.toLowerCase().includes(decodedSearchTerm.toLowerCase())
            ) {
                const shopPagePhotoUrl =
                    'https://traffiqueuat.azurewebsites.net/' +
                    shopPage.properties.shopPagePhoto?.[0]?.url;
                if (shopPagePhotoUrl) {
                    accum.add(shopPagePhotoUrl);
                }
            } else if (
                tags.some(tag =>
                    tag.toLowerCase().includes(decodedSearchTerm.toLowerCase()),
                )
            ) {
                const shopPagePhotoUrl =
                    'https://traffiqueuat.azurewebsites.net/' +
                    shopPage.properties.shopPagePhoto?.[0]?.url;
                if (shopPagePhotoUrl) {
                    accum.add(shopPagePhotoUrl);
                }
            }
            return accum;
        }, new Set<string>()),
    );

    return (
        <div>
            <div
                style={{
                    marginBottom: '13px',
                    display: 'flex',
                    marginLeft: '17px',
                }}
            >
                {searchTags.map((tag, index) => (
                    <span
                        key={index}
                        style={{
                            marginRight: '5px',
                            borderRadius: '5px',
                            fontSize: '50px',
                            fontFamily: '"source-sans-pro", sans-serif',
                            fontWeight: '700',
                            fontStyle: 'normal',
                            color: '#5C6767',
                        }}
                    >
                        {tag.toUpperCase()}
                    </span>
                ))}
            </div>
            <ul className={styles.masonryGrid}>
                {filteredShopPagePhotos.map((photoUrl, index) => {
                    const shopPage = items.find(
                        item =>
                            'https://traffiqueuat.azurewebsites.net/' +
                            item.properties.shopPagePhoto?.[0]?.url ===
                            photoUrl,
                    );

                    if (!shopPage) {
                        return null;
                    }

                    const url = shopPage.route.path;
                    const isVideo = photoUrl.endsWith('.mp4');

                    return (
                        <li key={index}>
                            <a href={url}>
                                {isVideo ? (
                                    <video
                                        className={styles.video}
                                        autoPlay
                                        muted
                                        playsInline
                                        loop
                                    >
                                        <source
                                            src={photoUrl}
                                            type="video/mp4"
                                        />
                                        Your browser does not support the video
                                        tag.
                                    </video>
                                ) : (
                                    <img src={photoUrl} alt="Shop Page" />
                                )}
                            </a>
                        </li>
                    );
                })}
            </ul>
        </div>
    );
};

export default Results;
