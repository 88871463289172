import React, { useState, useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { useSwipeable } from 'react-swipeable';
import { CommonNinjaWidget } from 'commonninja-react';
import Nav from '../../components/nav/Nav';
import styles from './clique-shop.module.scss';
import Facebook from '../../assets/img/facebook.png';
import Twitter from '../../assets/img/twitter.png';
import Instagram from '../../assets/img/instagram.png';
import Youtube from '../../assets/img/yt.png';
import Pinterest from '../../assets/img/pinterest.png';
import Mail from '../../assets/img/mail.png';
import Arrow from '../../assets/img/large_arrow.png';
import Close from '../../assets/img/close.png';
import Tiles from '../../components/tiles/Tiles';
import { useGetUmbraccoContentQuery } from '../../api/useGetUmbraccoContent';
import { generateSlug } from '../../middleware/generateSlug';
import Loader from '../../components/loader/Loader';
import ImageWithPolygon from '../../components/labelBoxViewer/LabelBoxViewer';
import VideoWithAnnotations from '../../components/labelBoxVideo/VideoWithAnnotations';
import CliqueNav from '../../components/cliqueNav/CliqueNav';
import VideoWithAnnotationsHardCoded from '../../components/labelBoxVideo/VideoWithAnnotationsHardCoded';

const CliqueShop: React.FC<{ data: any }> = ({ data }) => {
    const [selectedProductName, setSelectedProductName] = useState<
        string | null
    >(null);
    const [isSwipeEnabled, setIsSwipeEnabled] = useState<boolean>(true);
    const [mediaHeight, setMediaHeight] = useState<number | null>(null);
    const [shouldFetch, setShouldFetch] = useState(false);
    const {
        data: umbracoData,
        error,
        isLoading,
    } = useGetUmbraccoContentQuery('8d12733f-4762-46e2-bf5a-48569e8cca45', {
        enabled: shouldFetch,
    });
    const url = 'https://traffiqueuat.azurewebsites.net/';
    const navigate = useNavigate();
    const location = useLocation();
    const {
        models,
        date,
        location: loc,
        description,
        socials,
        mail,
        facebook,
        pinterest,
        twitter,
        photographer,
        photographerLogo,
        shopPagePhoto,
        uploadImageMap,
        uploadVideoMap,
        videoWidthIfHorizontal,
    } = data?.properties || {};

    useEffect(() => {
        window.scrollTo(0, 0);
        const handleResize = () => {
            setIsSwipeEnabled(window.innerWidth <= 768);
        };

        handleResize();
        window.addEventListener('resize', handleResize);

        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);

    const renderImage = (image: any) => {
        if (Array.isArray(image) && image.length > 0) {
            return (
                <img className={styles.img2} src={url + image[0].url} alt="" />
            );
        }
        return null;
    };

    const instagramLink = `https://www.instagram.com/${socials?.substring(1)}`;

    useEffect(() => {
        window.scrollTo(0, 0);
        const handleResize = () => {
            setIsSwipeEnabled(window.innerWidth <= 768);
        };

        handleResize();
        window.addEventListener('resize', handleResize);

        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);

    useEffect(() => {
        const timeoutId = setTimeout(() => {
            setShouldFetch(true);
        }, 1000);

        return () => clearTimeout(timeoutId);
    }, []);

    useEffect(() => {
        const handleHotspotClick = (event: Event) => {
            event.preventDefault();
            const item = (event.target as HTMLElement).closest(
                '.hotspot-item',
            ) as HTMLElement;
            const productName =
                item.querySelector('.tooltip-content p')?.textContent ||
                'No name';
            setSelectedProductName(productName);
            window.gtag('event', 'button_click', {
                event_category: 'Hotspot Clicked',
                event_label: productName,
                value: 1,
            });
        };

        const preventDefaultLinkBehavior = (event: MouseEvent) => {
            event.preventDefault();
            const targetLink = event.target as HTMLElement;
            if (targetLink.tagName === 'A' && targetLink.getAttribute('href')) {
                const link = targetLink.getAttribute('href');
                if (link && !link.startsWith('http')) {
                    navigate(link);
                }
            }
        };

        const checkForHotspotItems = () => {
            const commonNinjaComponent = document.querySelector(
                '.commonninja_component',
            );
            const hotspotItems =
                commonNinjaComponent?.shadowRoot?.querySelectorAll(
                    '.hotspot-item',
                );
            const links =
                commonNinjaComponent?.shadowRoot?.querySelectorAll('a');

            if (hotspotItems && hotspotItems.length > 0) {
                hotspotItems.forEach(item => {
                    item.addEventListener('click', handleHotspotClick);
                });
            }

            if (links && links.length > 0) {
                links.forEach(link => {
                    link.addEventListener('click', preventDefaultLinkBehavior);
                });
                return true;
            }

            return false;
        };

        const intervalId = setInterval(() => {
            if (checkForHotspotItems()) clearInterval(intervalId);
        }, 1000);

        return () => {
            clearInterval(intervalId);
        };
    }, [location.search, navigate]);

    const getCurrentPositionAndPathnames = () => {
        const currentPath = location.pathname;
        const basePath = '/shop-pages-clique/';
        const result = currentPath.substring(basePath.length);
        const pathnames = umbracoData?.properties?.photos.items.map(
            (item: any) => generateSlug(item.content.properties.pageName),
        );
        const position = pathnames.indexOf(result);
        return { position, pathnames, basePath };
    };

    const showNextPage = () => {
        const { position, pathnames, basePath } =
            getCurrentPositionAndPathnames();
        if (position >= 0 && position < pathnames.length - 1) {
            navigate(`${basePath}${pathnames[position + 1]}`);
            window.location.reload();
        }
    };

    const showPrevPage = () => {
        const { position, pathnames, basePath } =
            getCurrentPositionAndPathnames();
        if (position > 0) {
            navigate(`${basePath}${pathnames[position - 1]}`);
            window.location.reload();
        }
    };

    const handlers = useSwipeable({
        onSwipedLeft: showNextPage,
        onSwipedRight: showPrevPage,
        trackMouse: true,
    });

    return (
        <div className={styles.shop} {...(isSwipeEnabled ? handlers : {})}>
            <CliqueNav currentPage="clique" />
            <div className={styles.closeImgContainer}>
                <a href="/">
                    <img src={Close} className={styles.closeImg} />
                </a>
            </div>
            <div className={styles.heroImage} />
            <div className={styles.container}>
                <h1 className={styles.h1}>LIKE ANYTHING?</h1>
                <h3 className={styles.h3}>DISCOVER + BE INSPIRED + SHOP</h3>
                <div
                    className={styles.socialMediaDORO}
                    style={
                        videoWidthIfHorizontal > 30
                            ? {
                                backgroundColor: 'white',
                                display: 'flex',
                                justifyContent: 'center',
                                height: 'auto',
                                width: 'auto',
                            }
                            : { display: 'none' }
                    }
                >
                    {photographerLogo && (
                        <img
                            className={`${styles.img3} ${videoWidthIfHorizontal > 30
                                ? styles.img2DORO
                                : ''
                                }`}
                            src={url + photographerLogo[0]?.url}
                            alt=""
                        />
                    )}
                </div>
                <div
                    className={styles.img}
                    style={
                        videoWidthIfHorizontal > 30 ? { maxWidth: 'none' } : {}
                    }
                >
                    <img
                        src={Arrow}
                        alt="Left Arrow"
                        className={styles.arrowLeft}
                        onClick={showPrevPage}
                    />
                    {uploadVideoMap ==
                        '/media/nllgrmch/output-ostatecznyv3.json' ? (
                        <VideoWithAnnotationsHardCoded
                            isHorizontal={videoWidthIfHorizontal}
                            mapVideoData={
                                'https://traffiqueuat.azurewebsites.net/' +
                                uploadVideoMap
                            }
                        />
                    ) : uploadVideoMap ? (
                        <VideoWithAnnotations
                            isHorizontal={videoWidthIfHorizontal}
                            mapVideoData={
                                'https://traffiqueuat.azurewebsites.net/' +
                                uploadVideoMap
                            }
                        />
                    ) : uploadImageMap ? (
                        <ImageWithPolygon
                            mapData={
                                'https://traffiqueuat.azurewebsites.net/' +
                                uploadImageMap
                            }
                        />
                    ) : shopPagePhoto[0]?.url ? (
                        shopPagePhoto[0].url.endsWith('.mp4') ? (
                            <div className={styles.widgetContainer}>
                                <video
                                    className={styles.video}
                                    autoPlay
                                    controls
                                    loop
                                >
                                    <source
                                        src={`https://traffiqueuat.azurewebsites.net/${shopPagePhoto[0]?.url}`}
                                        type="video/mp4"
                                    />
                                    Your browser does not support the video tag.
                                </video>
                            </div>
                        ) : (
                            <div className={styles.widgetContainer}>
                                <img
                                    src={`https://traffiqueuat.azurewebsites.net/${shopPagePhoto[0]?.url}`}
                                    alt="Shop Page Photo"
                                />
                            </div>
                        )
                    ) : (
                        <div style={{ position: 'relative' }}>
                            <div
                                style={{
                                    position: 'absolute',
                                    top: '50%',
                                    left: '50%',
                                    display: 'block',
                                    width: 'auto',
                                    height: 'auto',
                                }}
                            >
                                <Loader />
                            </div>
                        </div>
                    )}
                    <>
                        {selectedProductName && (
                            <Tiles selectedProductName={selectedProductName} />
                        )}
                        <img
                            src={Arrow}
                            alt="Right Arrow"
                            className={styles.arrowRight}
                            onClick={showNextPage}
                        />
                    </>
                </div>
                {models && (
                    <h3
                        className={styles.models}
                        style={
                            videoWidthIfHorizontal > 30
                                ? { maxWidth: 'none' }
                                : {}
                        }
                    >
                        {models}
                    </h3>
                )}
                {date && loc && (
                    <h3
                        className={styles.date}
                        style={
                            videoWidthIfHorizontal > 30
                                ? { maxWidth: 'none' }
                                : {}
                        }
                    >
                        {date} | {loc}
                    </h3>
                )}
                <h3
                    className={styles.models}
                    style={
                        videoWidthIfHorizontal > 30
                            ? { maxWidth: 'none' }
                            : { display: 'none' }
                    }
                >
                    STORY
                </h3>
                {description && (
                    <h3
                        className={styles.description}
                        style={
                            videoWidthIfHorizontal > 30
                                ? { maxWidth: 'none' }
                                : {}
                        }
                    >
                        {description}
                    </h3>
                )}
                <h3 className={styles.socials}>
                    <a
                        href={instagramLink}
                        target="_blank"
                        rel="noopener noreferrer"
                    >
                        {socials}
                    </a>
                </h3>
                <div className={styles.socialMediaContainer}>
                    {facebook && (
                        <a href={facebook}>
                            <img src={Facebook} alt="Facebook" />
                        </a>
                    )}
                    {twitter && (
                        <a href={twitter}>
                            <img src={Twitter} alt="Twitter" />
                        </a>
                    )}
                    {pinterest && (
                        <a href={pinterest}>
                            <img
                                src={Youtube}
                                className={styles.yt}
                                alt="Youtube"
                            />
                        </a>
                    )}
                    {mail && (
                        <a href={'mailto:' + mail}>
                            <img src={Mail} alt="Mail" />
                        </a>
                    )}
                </div>
                <a href="/photographers">
                    <div className={styles.socialMediaContainerPhotographer}>
                        <div
                            className={styles.socialMedia}
                            style={
                                videoWidthIfHorizontal > 30
                                    ? { display: 'none' }
                                    : { backgroundColor: 'white' }
                            }
                        >
                            {photographerLogo && (
                                <img
                                    className={styles.img2}
                                    src={url + photographerLogo[0]?.url}
                                    alt=""
                                />
                            )}
                        </div>
                        <div className={styles.info}>
                            {photographer && (
                                <>
                                    <h1>{photographer}</h1>
                                    <p>PHOTOGRAPHER</p>
                                </>
                            )}
                        </div>
                    </div>
                </a>
            </div>
        </div>
    );
};

export default CliqueShop;
