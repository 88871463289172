import React from 'react';
import styles from './clique-photo-item.module.scss';
import { Link } from 'react-router-dom';

interface PhotoItemProps {
    url: string;
    pageName: string;
}

function CliquePhotoItem({ url, pageName }: PhotoItemProps) {
    const isVideo = url.endsWith('.mp4');

    return (
        <>
            <Link to={'/shop-pages-clique/' + pageName}>
                {isVideo ? (
                    <video
                        className={styles.video}
                        autoPlay
                        muted
                        playsInline
                        loop
                    >
                        <source
                            src={'https://traffiqueuat.azurewebsites.net/' + url}
                            type="video/mp4"
                        />
                        Your browser does not support the video tag.
                    </video>
                ) : (
                    <img
                        src={'https://traffiqueuat.azurewebsites.net/' + url}
                        alt="media"
                    />
                )}
            </Link>
            <p className={styles.margin}></p>
        </>
    );
}

export default CliquePhotoItem;
