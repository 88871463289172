import React from 'react';
import Nav from '../../components/nav/Nav';
import styles from './photographers.module.scss';
import { useGetUmbracoPhotographers } from '../../api/useGetUmbracoPhotographers';

type UmbraccoImage = {
    url: string;
};

const Photographers: React.FC = () => {
    const contentTypeAlias2 = 'photographerInfo';
    const {
        data: photographers,
        error,
        isLoading,
    } = useGetUmbracoPhotographers(contentTypeAlias2);

    const renderImage = (image: UmbraccoImage[]) => {
        if (Array.isArray(image) && image.length > 0) {
            return (
                <img
                    className={styles.img}
                    src={
                        'https://traffiqueuat.azurewebsites.net/' + image[0].url
                    }
                    alt="Photographer"
                />
            );
        }
        return null;
    };

    return (
        <div className="Photographer">
            <Nav currentPage="photographers" />
            <div className={styles.heroImage} />

            {isLoading && <p>Loading...</p>}
            {error && <p>Error loading photographers.</p>}
            <div className={styles.photographersContainer}>
                {photographers &&
                    photographers.map((photographer: any) => (
                        <a
                            key={photographer.id}
                            href={`/photographer/${photographer.properties.photographerName.toLowerCase().replace(/\s+/g, '-')}`}
                        >
                            <div className={styles.container}>
                                {renderImage(photographer.properties.image)}
                                <div className={styles.text}>
                                    <p className={styles.paragraph2}>
                                        PHOTOGRAPHER
                                    </p>
                                    <h1 className={styles.heading}>
                                        {
                                            photographer.properties
                                                .photographerName
                                        }
                                    </h1>
                                    <p className={styles.paragraph}>
                                        {photographer.properties.description}
                                    </p>
                                </div>
                            </div>
                        </a>
                    ))}
            </div>
        </div>
    );
};

export default Photographers;
