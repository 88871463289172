import React from 'react';
import { Link } from 'react-router-dom';
import Nav from '../../components/nav/Nav';
import img from '../../assets/img/photographer.png';
import styles from './photographer.module.scss';
import Quick from '../../components/quick/Quick';
import PhotoGrid from '../../components/photoGrid/PhotoGrid';
import { useGetUmbraccoContentQuery } from '../../api/useGetUmbraccoContent';
import PhotoGridPhotographer from '../../components/photoGridPhotographer/PhotoGridPhotographer';

type UmbraccoImage = {
    url: string;
};

const Photographer: React.FC<{ photographer: any }> = ({ photographer }) => {
    const { photographerName, websiteLink, description, image, cities } =
        photographer?.properties || {};

    const renderImage = (image: string | UmbraccoImage[]) => {
        if (Array.isArray(image) && image.length > 0) {
            return (
                <img
                    className={styles.img}
                    src={
                        'https://traffiqueuat.azurewebsites.net/' + image[0].url
                    }
                    alt=""
                />
            );
        }
        return null;
    };

    const renderCities = (cities: { items: any[] }) => {
        if (cities?.items) {
            return cities.items.map((city, index) => (
                <React.Fragment key={city.content.id}>
                    <Link
                        to={`/search/${city.content.properties.cityname.replace(/\s+/g, '-').toLowerCase()}`}
                    >
                        {city.content.properties.cityname}
                    </Link>
                    {index < cities.items.length - 1 && <br />}
                </React.Fragment>
            ));
        }
        return '';
    };

    return (
        <div className="Photographer">
            <Nav currentPage="photographer" />
            <div className={styles.heroImage} />
            <div className={styles.containerImage}>
                {renderImage(image)}
                <div className={styles.text}>
                    <p className={styles.paragraph2}>PHOTOGRAPHER</p>
                    <h1 className={styles.heading}>{photographerName}</h1>
                </div>
            </div>
            <div className={styles.container}>
                <div className={styles.text}>
                    <p className={styles.paragraph}>{description}</p>
                    <div className={styles.button}>
                        <button>
                            <u>{websiteLink}</u>
                        </button>
                    </div>
                    <p className={styles.cities}>{renderCities(cities)}</p>
                </div>
            </div>
            <div className={styles.photoGrid}>
                <PhotoGridPhotographer />
            </div>
        </div>
    );
};

export default Photographer;
