import React, { useState, useEffect } from 'react';
import { useGetUmbracoContentByTypeQuery } from '../../api/useGetUmbracoContentByTypeQuery';
import styles from './search.module.scss';
import Search2 from '../../assets/img/search2.png';
import Loader from '../loader/Loader';

interface Item {
    id: string;
    content: {
        properties: {
            productName: string;
            brand: string;
            minatureImage: Array<{
                url: string;
                name: string;
            }>;
        };
    };
}

interface UmbracoContent {
    contentType: string;
    name: string;
    createDate: string;
    updateDate: string;
    id: string;
    route: {
        path: string;
    };
    properties: {
        addItem: {
            items: Item[];
        } | null;
        shopPagePhoto: Array<{
            url: string;
            name: string;
        }> | null;
        location?: string | null;
        tags?: string[] | null;
    };
}

interface ContentListProps {
    searchTerm: string;
}

const ContentList: React.FC<ContentListProps> = ({ searchTerm }) => {
    const contentTypeAlias = 'addShopPage';
    const { data, error, isLoading } =
        useGetUmbracoContentByTypeQuery(contentTypeAlias);
    const [searchTags, setSearchTags] = useState<string[]>([]);

    useEffect(() => {
        setSearchTags(searchTerm.split(' ').filter(tag => tag.trim() !== ''));
    }, [searchTerm]);

    if (isLoading) return <Loader />;
    if (error) return <div>Error: {error.message}</div>;

    const items = data || [];

    const filteredShopPagePhotos = Array.from(
        items.reduce<Set<string>>((accum, shopPage: UmbracoContent) => {
            if (shopPage.properties.addItem?.items) {
                const matchedItems = shopPage.properties.addItem.items.filter(
                    (subItem: Item) => {
                        const { productName, brand } =
                            subItem.content.properties;
                        const location = shopPage.properties.location ?? '';
                        const tags = shopPage.properties.tags ?? [];
                        const searchKeywords = searchTerm
                            .toLowerCase()
                            .split(' ');

                        return searchKeywords.every(
                            keyword =>
                                productName.toLowerCase().includes(keyword) ||
                                brand.toLowerCase().includes(keyword) ||
                                (location &&
                                    location.toLowerCase().includes(keyword)) ||
                                tags.some(tag =>
                                    tag.toLowerCase().includes(keyword),
                                ),
                        );
                    },
                );

                if (matchedItems.length > 0) {
                    const shopPagePhotoUrl =
                        'https://traffiqueuat.azurewebsites.net/' +
                        shopPage.properties.shopPagePhoto?.[0]?.url;
                    if (shopPagePhotoUrl) {
                        accum.add(shopPagePhotoUrl);
                    }
                }
            }
            return accum;
        }, new Set<string>()),
    );

    return (
        <div className={styles.photoGrid}>
            <div
                style={{
                    marginBottom: '13px',
                    display: 'flex',
                    marginLeft: '17px',
                }}
            >
                {searchTags.map((tag, index) => (
                    <span
                        key={index}
                        style={{
                            marginRight: '5px',
                            borderRadius: '5px',
                            fontSize: '50px',
                            fontFamily: '"source-sans-pro", sans-serif',
                            fontWeight: '700',
                            fontStyle: 'normal',
                            color: '#5C6767',
                        }}
                    >
                        {tag}
                    </span>
                ))}
            </div>
            <ul className={styles.masonryGrid}>
                {filteredShopPagePhotos.map((photoUrl, index) => {
                    const shopPage = items.find(
                        item =>
                            'https://traffiqueuat.azurewebsites.net/' +
                            item.properties.shopPagePhoto?.[0]?.url ===
                            photoUrl,
                    );

                    if (!shopPage) {
                        return null;
                    }

                    const url = shopPage.route.path;
                    return (
                        <li key={index}>
                            <a href={url}>
                                <img src={photoUrl} alt="Shop Page" />
                            </a>
                        </li>
                    );
                })}
            </ul>
        </div>
    );
};

export default ContentList;
