import axios from 'axios';
import { useQuery, UseQueryOptions } from 'react-query';

interface Item {
    id: string;
    content: {
        properties: {
            productName: string;
            brand: string;
            minatureImage: Array<{
                url: string;
                name: string;
            }>;
        };
    };
}

interface UmbracoContent {
    contentType: string;
    name: string;
    createDate: string;
    updateDate: string;
    id: string;
    route: {
        path: string;
    };
    properties: {
        addItem: {
            items: Item[];
        } | null;
        shopPagePhoto: Array<{
            url: string;
            name: string;
        }> | null;
    };
}

interface ApiResponse {
    total: number;
    items: UmbracoContent[];
}

export const useGetUmbracoPhotographers = (
    contentTypeAlias: string,
    options?: UseQueryOptions<UmbracoContent[], Error>,
) => {
    const getUmbracoContentByType = async (): Promise<UmbracoContent[]> => {
        try {
            const response = await axios.get<ApiResponse>(
                'https://traffiqueuat.azurewebsites.net/umbraco/delivery/api/v2/content',
                {
                    params: {
                        filter: [`contentType:${contentTypeAlias}`],
                        take: 1000000,
                    },
                    paramsSerializer: params => {
                        return Object.entries(params)
                            .map(
                                ([key, value]) =>
                                    `${key}=${encodeURIComponent(Array.isArray(value) ? value.join(',') : value)}`,
                            )
                            .join('&');
                    },
                },
            );
            return response.data.items;
        } catch (error: any) {
            const status = error.response?.status || 500;
            throw new Error(
                `Error fetching content with type ${contentTypeAlias}: ${status}`,
            );
        }
    };

    return useQuery<UmbracoContent[], Error>(
        ['getUmbracoContentByType', contentTypeAlias],
        getUmbracoContentByType,
        {
            ...options,
            enabled: !!contentTypeAlias,
        },
    );
};
